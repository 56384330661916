import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';


const config = {
    apiKey: "AIzaSyBJk6-o3yhBYvP4T0ELh_3JYph1I6g_lK4",
    authDomain: "wod-whiteboard.firebaseapp.com",
    databaseURL: "https://wod-whiteboard.firebaseio.com",
    projectId: "wod-whiteboard",
    storageBucket: "wod-whiteboard.appspot.com",
    messagingSenderId: "690141020527",
    appId: "1:690141020527:web:6f766a97e8c15a5131a89a",
    measurementId: "G-YPT4LL8J6K"
}; 

firebase.initializeApp(config);

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();


const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
const localUser = JSON.parse(window.localStorage.getItem(key))


export const USER = ()=>auth.currentUser;

export const isAuth = ()=> {
  return auth.currentUser || localUser;
}
