import { database, storage, auth } from 'firebase_config'

export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const FETCH_WOD = 'FETCH_WOD';
export const FETCH_RESULTS = 'FETCH_RESULTS';

const users = database.ref('users');
const wods = database.ref('wods');
const results = database.ref('results');

const pics = storage.ref('userPhotos')
// =================================
// ==========WOD Actions===========
// =================================

export function fetchWOD(dateString) {
    return dispatch => {
        wods.orderByChild('date').equalTo(dateString).on('value', snapshot => {
            dispatch({
                type: FETCH_WOD,
                payload: snapshot ? snapshot.val() : {}
            })
        })
    }
}

export function createWOD(values) {
    return () => wods.push(values)
}
export function updateWOD(wodId, values) {
    return () => wods.child(wodId).update(values)
}
export function deleteWOD(wodId,cb) {
    return () => {
        wods.child(wodId).remove().then(cb)
}}


// =================================
// ==========Results Actions===========
// =================================

export function fetchResults(dateString) {
    return dispatch => results.orderByChild('date').equalTo(dateString).on('value', snapshot => {
            dispatch({
                type: FETCH_RESULTS,
                payload: snapshot ? snapshot.val() : {}
            })
        })
    
}

export function addResults(values, cb) {
    return () => results.push(values).then(cb)
}

export function editResults(values, resultId, cb) {
    return () => results.child(resultId).update(values).then(cb)
}

export function deleteResult(resultId, cb) {
    return () => results.child(resultId).remove().then(cb)
}

// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
    const { email, password } = values;
    return () => auth.signInWithEmailAndPassword(email, password)
    .then(res=>{
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}

export function signOut(cb) {
    return ()=>auth.signOut()
    .then(cb)
    .catch(err=>cb(err))
}

export function updateProfileImage(uid, image, name) {
    const { key } = users.child(uid).child('profileImage').push()
    return dispatch => pics.child(`${uid}/profileImages/${key}/${name}`).put(image).then((snapshot) => {
        snapshot.ref.getDownloadURL().then(url => {
            users.child(uid).update({profileImage: url}).then(dispatch(verifyAuth()))
        })
    })
}
export function resetPassword(email, cb) {
    return () => auth.sendPasswordResetEmail(email)
    .then(res=>{
        console.log(res)
            cb({type: 'User', res});
            })
        .catch(res=>cb({type: 'Error', res}))
}

export function signUp(values, cb) {
    const { email, password, firstName, lastName, gender } = values;
    return () => auth.createUserWithEmailAndPassword(email, password)
    .then(res=>{
        const { uid } = res.user
        users.child(uid).set({
            email,
            firstName,
            lastName,
            gender,
            uid
        })
        cb({type: 'User', res});
        })
    .catch(res=>cb({type: 'Error', res}))
}


export function verifyAuth() {
    return dispatch=>{
        const key = 'firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]';
        auth.onAuthStateChanged(user=>{
            if (user) {
                users.child(user.uid).once('value').then(snapshot=>{
                    const dbUser = snapshot.val()
                    const userString = JSON.stringify(user)
                    window.localStorage.setItem(key, userString)
                    dispatch({
                        type: AUTH_USER,
                        payload: {...user, ...dbUser}
                    })
                })
                
            } else {
                window.localStorage.removeItem(key)
                dispatch({
                    type: UNAUTH_USER
                })
            }
        });
    };
}


