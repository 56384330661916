export const roundsAndReps = {
  rounds: 0,
  reps: 0
}

export const forTime = {
  minutes: 0,
  seconds: 0
}

export const weight = {
  sets: 0,
  reps: 0,
  weight: 0
}

export const distance = {
  distance: 0
}

export const separators = {
  roundsAndReps: [` - `],
  forTime: [':'],
  weight: [` x `, ` @ `],
  distance: []
}

export default {
  roundsAndReps,
  forTime,
  weight,
  distance,
  separators
}

