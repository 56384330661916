import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  IconButton
} from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik';

import { useConfirm } from 'components/confirm';

import { createWOD, updateWOD, deleteWOD } from 'actions';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(4)
  },
  iconButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
  }
}))

const NewWOD =({
  open,
  onClose,
  wodCount,
  date,
  wod,
  editId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  return (
    <Formik
      enableReinitialize
      initialValues={wod ? wod :{
        name: '',
        content: '',
        type: '',
        date
      }}
      onSubmit={
        (values) => {
          onClose();
          editId
          ? dispatch(updateWOD(editId, values))
          : dispatch(createWOD(values))
        }
      }
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => {
        return (
          <Dialog
            open={open}
            onClose={onClose}
          >
            <Form>
            <DialogContent>
              <TextField
                name="name"
                value={values.name}
                onChange={handleChange}
                label="WOD Name"
                fullWidth
                className={classes.input}
              />
              <TextField
                name="content"
                value={values.content}
                onChange={handleChange}
                label="Workout"
                multiline
                rows={5}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                name='type'
                value={values.type}
                onChange={handleChange}
                label="Type"
                fullWidth
                select
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="roundsAndReps">Rounds and Reps</MenuItem>
                <MenuItem value="forTime">For Time</MenuItem>
                <MenuItem value="weight">Weight</MenuItem>
                <MenuItem value="distance">Distance</MenuItem>
              </TextField>
            </DialogContent>
            <DialogActions>
              {editId ? <IconButton
                className={classes.iconButton}
                onClick={() => {
                // dispatch(deleteWOD(editId, onClose))}
                return confirm({
                  title: 'Delete WOD!',
                  description: 'Are you sure you want to delete this WOD?',
                  buttonProps: {
                    confirmColor: 'secondary',
                    confirmVariant: 'contained'
                  }
                })
                  .then(() => {
                    dispatch(deleteWOD(editId))
                  })
                  .catch(() => onClose())
                }}
              >
                <DeleteForever />
              </IconButton>: null}
              <Button onClick={onClose}>Close</Button>
              <Button type="submit" color="primary">
                {editId ? 'Update' : 'Create'}</Button>
            </DialogActions>
            </Form>
          </Dialog>
        )
      }}
    </Formik>
  )
}

export default NewWOD;