import { map, filter, keys, find, size } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GirlAthlete,GuyAthlete } from 'images/myIcons';

import AddResults from 'components/addResults';
import resultTypes from 'components/resultTypes';

import male from 'images/male.jpg';
import female from 'images/female.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    paddingTop: 0,
  },
  inline: {
    //display: 'inline',
  },
  button: {
    // position: 'fixed',
    // // bottom: -13,
    // left: 0,
    // right: 0,
    fontSize: '.8em',
    minWidth: 105,
    margin: 'auto'
  },
  buttonDiv: {
    display: 'block'
  },
  card: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    minHeight: 100,
    maxHeight: 165,
    position: 'relative',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    padding: theme.spacing(1),
    paddingTop: 0,
    [theme.breakpoints.only('xs')] : {
      maxWidth: 155,
    }
  },
  cover: {
    width: 95,
  },
  rx: {
    height: 20,
    width: 20,
    minWidth: 20,
    padding: 0,
    borderRadius: '50%',
    position: 'absolute',
    top: theme.spacing(.5),
    right: theme.spacing(.5),
    fontSize: '.7em',
    textTransform: 'inherit',
    fontWeight: 'bold'
    // backgroundColor: blue[400],
    // '&:hover': {
    //   backgroundColor: blue[600],
    // },
  },
  genders: {
    margin: 'auto',
    width: 161
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    paddingBottom: 0
  },
  note: {
    whiteSpace: 'pre-wrap'
  }
}))

const Result = ({
  result: {
    firstName,
    lastName,
    profileImage,
    type,
    notes,
    gender,
    rx,
    score
  },
  result
}) => {
  const classes = useStyles();
  const [note, setNote] = useState(null)
  return (
    
    <Card className={classes.card}>
      <CardMedia
        className={classes.cover}
        image={profileImage ? profileImage : (gender === 'female' ? female : male)}
        title="Profile Pic"
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          {rx &&
          <Button
            className={classes.rx}
            color="primary"
            // variant="contained"
          >
            Rx
          </Button>}
          <Typography component="div" variant="h6">
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography variant="subtitle1" color="primary">
              {resultTypes[type]
              ? map(keys(resultTypes[type]), (inputName, index) => {
                return `${result[inputName]}${resultTypes.separators[type][index] || ''}`
              })
              : score  
              }
          </Typography>
          <Typography component="pre" variant="subtitle2" color="textSecondary" onClick={() => setNote(notes)}>
              {notes}
          </Typography>
          <Dialog open={!!note} onClose={() => setNote(null)}>
            <DialogContent className={classes.note}>{notes}</DialogContent>
          </Dialog>
        </CardContent>
        <div className={classes.controls}>
        </div>
      </div>
    </Card>
  )
}

const Results = ({
  wod,
  wodId,
  open,
  onClose
}) => {
  const classes = useStyles();
  const user = useSelector(state => state.authUser);
  const [openScore, setOpenScore] = useState(false);
  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(true);
  const dbResults = useSelector(state => state.results)
  const results = map(dbResults, (o,k) => ({ ...o, resultId: k }))
  const result = find(results, {uid: user.uid, wodId});
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className={classes.header}>{wod.name}</DialogTitle>
      <DialogContent className={classes.root}>
      <div className={classes.genders}>
        <IconButton
          color={male ? 'primary' : 'default'}
          onClick={()=>setMale(!male)}
          className={classes.iconButton}
        >
          <GuyAthlete/>
        </IconButton>
        <IconButton
          color={female ? 'primary' : 'default'}
          onClick={()=>setFemale(!female)}
          className={classes.iconButton}
        >
          <GirlAthlete />
        </IconButton>
      </div>
        {map(filter(results, {wodId}), (result, resultId) => {
        if (result.gender === 'male' && !male) return;
        if (result.gender === 'female' && !female) return;
        return (
          <Result key={resultId} result={result}/>
        )})}
        <AddResults
          open={openScore}
          onClose={
            () => setOpenScore(false)
          }
          wod={wod}
          wodId={wodId}
          user={user}
          result={result}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => setOpenScore(true)}
          color="primary"
          className={classes.button}
          variant="contained"
        >
          {size(result) > 0 ? 'Edit' : 'Enter'} Score
        </Button>
        
      </DialogActions>
    </Dialog>
    )
}

export default Results;