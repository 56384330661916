import { map, size } from 'lodash';
import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  IconButton
} from '@material-ui/core';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { Formik, Form } from 'formik';

import { useConfirm } from 'components/confirm';
import { addResults, editResults, deleteResult } from 'actions';
import resultTypes from 'components/resultTypes';

const useStyles = makeStyles(theme => ({
  rx: {
    height: 30,
    width: 30,
    minWidth: 30,
    borderRadius: '50%',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    fontSize: '.6em',
    textTransform: 'inherit'
  },
  rxOn: {
    backgroundColor: blue[400],
    '&:hover': {
      backgroundColor: blue[600],
    },
  },
  deleteResult: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1)
  }
}));

const AddResults = ({
  open,
  onClose,
  wodId,
  wod,
  user: {
    firstName,
    lastName,
    uid,
    gender,
    age,
    profileImage
  },
  result
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const scoreValues = resultTypes[wod.type] || { score: 0 };
  const capitalize = str => str.replace(/^\w/, c => c.toUpperCase())
  return (
    <Formik
      enableReinitialize
      initialValues={result || {
        ...scoreValues,
        ...wod,
        notes: '',
        rx: false,
        wodId,
        firstName,
        lastName: lastName || '',
        uid,
        gender: gender || '',
        age: age || '',
        profileImage: profileImage || ''
      }}
      onSubmit={
        (values, actions) => {
        const cb =  () => {
            actions.resetForm();
            onClose();
        }
          dispatch(
          result
          ? editResults(values, result.resultId, cb)
          : addResults(values, cb)
          )
        }
      }
    >
    {
      ({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue
      }) => {
        const inputQty = size(resultTypes[wod.type]);
        return (
          <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
          >
            <Form>
              <DialogTitle onClick={() => console.log(result)}>
                Score
                <Button
                  className={clsx(classes.rx, values.rx ? classes.rxOn : '')}
                  color="default"
                  variant="contained"
                  onClick={() => {
                    setFieldValue('rx', !values.rx)
                  }}
                >
                  Rx
                </Button>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  {map(scoreValues, (inputValue, inputName) => {
                    return (
                      <Grid item xs={inputQty > 2 ? 4 : 6} key={inputName}>
                        <TextField
                          name={inputName}
                          value={values[inputName]}
                          onChange={handleChange}
                          variant="outlined"
                          label={capitalize(inputName)}
                        />
                      </Grid>
                    )})}
                <Grid item xs={12}>
                  <TextField
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    variant="outlined"
                    label="Notes"
                    multiline
                    fullWidth
                    rows={3}
                  />
                </Grid>
              </Grid>
              </DialogContent>
              <DialogActions>
                {result &&
                <IconButton
                  className={classes.deleteResult}
                  onClick={()=>confirm({
                  title: 'Delete Score!',
                  description: 'Are you sure you want to delete this score card?',
                  buttonProps: {
                    confirmColor: 'secondary',
                    confirmVariant: 'contained'
                  }
                })
                  .then(() => {
                    dispatch(deleteResult(result.resultId))
                  })
                  .catch(() => onClose())
                }
                >
                  <DeleteForever />
                </IconButton>}
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary">Submit</Button>
              </DialogActions>
            </Form>
          </Dialog>
        )
      }
    }
    </Formik>
  )
}

export default AddResults;
