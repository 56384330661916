import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useDropzone} from 'react-dropzone';
import {
  Card,
  CardMedia,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Paper,
  IconButton,
} from '@material-ui/core';
import WcIcon from '@material-ui/icons/Wc';
import HeightIcon from '@material-ui/icons/Height';
import SpeedIcon from '@material-ui/icons/Speed';
import EventIcon from '@material-ui/icons/Event';

import { makeStyles } from '@material-ui/core/styles';

import Crop from 'components/profileImage'

import { updateProfileImage } from 'actions';

const useStyles = makeStyles(theme => ({
  stats: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1.5),
  },
  container: {
    marginTop: theme.spacing(8),
    border: '1px solid black',
    height: 300,
    width: 300
  },
  img : {
    display: 'block',
    width: 'auto',
    height: '100%'
  },
  card: {
    marginTop: theme.spacing(8),
    width: 'calc(85%)',
    margin: 'auto',
  },
  media: {
    [theme.breakpoints.up('sm')]: {
      height: 210,
    },
    [theme.breakpoints.down('sm')]: {
      height: 175,
    },
    [theme.breakpoints.down('xs')]: {
      height: 140,
    },
    opacity: '40%'
  },
  avatar: {
    height: 80,
    width: 80,
    margin: 'auto',
    border: '2px solid white',
    borderRadius: '50%'
  },
  dropzone: {
    transform: 'translate(0%, -50%)',
    margin: 'auto',
    width: 80,
    borderRadius: '50%',
    '& :focus': {
      outline: '-webkit-focus-ring-color auto 16px'
    }
  },
  cardContent: {
    marginTop: -44,
    textAlign: 'center',
    paddingRight: theme.spacing(3)
  },
  iconButton: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    position: 'absolute',
    top: -20,
    right: -20,
    backgroundColor: theme.palette.background.paper,
    opacity: 100
  },
  paper: {
    position: 'relative',
  }
}))
const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser);
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [edit, setEdit] = useState(false)
  const { getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      console.log(acceptedFiles[0])
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]) /*global URL */
      }));
      setEdit(true);
    }
  });
  const url = user.profileImage || "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
  const Stat = ({stat, label, icon}) => (
    <Grid item xs={12} md={6} lg={3}>
      <Paper elevation={2} className={classes.paper}>
        <IconButton
          className={classes.iconButton}
      >{icon}</IconButton>
        <Typography variant="h5">{stat || '-'}</Typography>
        <Typography variant="caption">{label}</Typography>
      </Paper>
    </Grid>
  )
  const capitalStr = phrase => phrase.replace(/^\w/, c => c.toUpperCase()); 
  return (
    <React.Fragment>
    <Card className={classes.card}>
      
        <CardMedia
          className={classes.media}
          image={url}
          title="Good Boy"
        />
        <div {...getRootProps({className: classes.dropzone})}>
          <input {...getInputProps()} />
          <Avatar
            alt="Good Boy"
            src={url}
            className={classes.avatar}
          />
        </div>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5">{`${user.firstName} ${user.lastName}`}</Typography>
          <Typography variant="subtitle2">{user.email}</Typography>
          <div className={classes.stats}>
            <Grid container spacing={4}>
              <Stat stat={capitalStr(user.gender)} label="Gender" icon={<WcIcon />} />
              <Stat stat={user.age} label="Age" icon={<EventIcon />} />
              <Stat stat={user.height} label="Height" icon={<HeightIcon />} />
              <Stat stat={user.weight} label="Weight" icon={<SpeedIcon />} />
            </Grid>
          </div>
        </CardContent>
    </Card>
      <Crop
        open={edit}
        onClose={() => {
          setEdit(false);
          setFile(null);
        }}
        imageFile={file}
        getBlob={(blob, preview) => {
          dispatch(updateProfileImage(user.uid, blob, file.name));
          setEdit(false);
        }}
      />
    </React.Fragment>
  )
}

export default Profile;
