import React, { useState, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import { makeStyles } from  '@material-ui/core/styles';

import { getCroppedImg } from 'components/cropImage'

const useStyles = makeStyles(theme => ({
  root: {
    height: 391,
  },
  cropContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '80px',
  },
  container: {
    minHeight: 391,
    // paddingBottom: 80
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '50%',
    transform: 'translateX(-50%)',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
  },
  slider: {
    padding: '22px 0px'
  }

}))

const Crop = ({
  open,
  onClose,
  imageFile,
  getBlob
}) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedPixels] = useState({});
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    return setCroppedPixels(croppedAreaPixels);
  }, [])
  const updatePhoto = async () => {
        const croppedImage = await getCroppedImg(
            imageFile.preview,
            croppedAreaPixels,
            imageFile.name
        )
        getBlob(croppedImage)
    }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent className={classes.container}>
      { // <div className={classes.cropContainer}>
        <Cropper
          image={imageFile && imageFile.preview}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      // </div>
      }
      <div className={classes.controls}>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom)}
          className={classes.slider}
        />
      </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={updatePhoto} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default Crop;
