import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Fab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import { useSelector, connect } from 'react-redux';

import { fetchWOD, fetchResults } from 'actions';

import NewWOD from 'components/newWod';
import Results from 'components/results';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  picker: {
    marginTop: theme.spacing(8)
  },
  card: {
    marginTop: theme.spacing(4)
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    margin: 'auto',
    color: theme.palette.primary.light
  },
  whiteboard: {
    margin: 'auto'
  }
}))

const WOD = ({ fetchWOD, fetchResults }) => {
  const classes = useStyles();
  const formatDate = (dateObject) => {
    const year = dateObject.getFullYear()+"";
    const month = (dateObject.getMonth()+1)+"";
    const day = dateObject.getDate()+"";
    const dateFormat = year + "-" + month + "-" + day;
    return (dateFormat)
  };
  const [selectedDate, setSeletedDate] = useState(new Date());
  const [dateFormated, setDateFormated] = useState(formatDate(new Date()));
  const [edit, setEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [openWhiteboard, setOpenWhiteboard] = useState(null)
  const wod = useSelector(state => state.wod);
  const user = useSelector(state => state.authUser);
  const results = useSelector(state => state.results);
  const handleDateChange = (date) => {
    setDateFormated(formatDate(date));
    setSeletedDate(date);
  }
  useEffect(() => {
      fetchResults(dateFormated);
      fetchWOD(dateFormated);
      }, [dateFormated, fetchWOD, fetchResults])
  return (
    <Fragment>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        className={classes.picker}
        autoOk
        label="WOD Date"
        value={selectedDate}
        onChange={handleDateChange}
        animateYearScrolling
      />
    </MuiPickersUtilsProvider>
      {_.size(wod) > 0 ? _.map(wod, (wod, wodId) => {
        return (
          <Card variant="outlined" className={classes.card} key={wodId}>
            <CardHeader
              title={wod && wod.name ? wod.name : 'No WOD Entered'}
              action={user && user.role === 'admin' &&
                <IconButton
                  onClick={() => {
                  setOpen(true);
                  setEdit(wodId);
                  }}>
                  {wod && wod.name ? <CreateIcon /> : <AddIcon />}
                </IconButton>
            }
            />
            <CardContent onClick={() => console.log(wod)}>
              {wod && wod.content ? <pre>{wod.content}</pre> : null}
            </CardContent>
            <CardActions>
              <Button
                  className={classes.whiteboard}
                  onClick={() => setOpenWhiteboard(wodId)}
                  color="primary"
              >
                Whiteboard
              </Button>
            </CardActions>
            <Results
              results={results}
              wod={wod}
              wodId={wodId}
              open={wodId === openWhiteboard}
              onClose={() => setOpenWhiteboard(null)}
            />
          </Card>
        )
      })
        :
        <Card variant="outlined" className={classes.card}>
            <CardHeader
              title={wod && wod.name ? wod.name : 'No WOD'}
              action={user && user.role === 'admin' &&
                <IconButton
                  onClick={() => {
                  setOpen(true);
                  }}>
                  {wod && wod.name ? <CreateIcon /> : <AddIcon />}
                </IconButton>
            }
            />
            <CardContent onClick={() => console.log(wod)}>
              {wod && wod.content ? <pre>{wod.content}</pre> : null}
            </CardContent>
          </Card>
      }
      {user && user.role ==='admin' &&
      <Fab className={classes.fab} color="primary" onClick={() => setOpen(true)}>
        <AddIcon />
      </Fab>}
      <NewWOD
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(null);
        }}
        date={dateFormated}
        wod={wod && wod[edit] ? wod[edit] : null}
        editId={edit}
      />
    </Fragment>
  );
}

export default connect(null, { fetchWOD, fetchResults })(WOD);
