import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const GirlAthlete = () => {
  return <SvgIcon
    viewBox={"0 0 297 297"}
  >
    <path d="M296.706,281.498c-1.212-4.847-2.106-9.515-2.97-14.03c-3.645-19.044-7.415-38.736-34.906-44.234l-29.324-5.865
    	c-6.109-1.222-10.542-6.631-10.542-12.86v-14.034c15.828-11.311,26.61-27.933,30.443-47.1l4.02-20.101h3.324
    	c5.395,0,9.77-4.374,9.77-9.77v-37.73c0-5.395-4.374-9.77-9.77-9.77h-0.741c-13.544-26.486-41.093-44.672-72.828-44.672
    	c-10.59,0-20.706,2.046-30.002,5.727c-4.56-6.491-12.999-15.764-26.258-20.558c-15.45-5.588-32.965-3.714-52.058,5.566
    	C40.562,28.743,38.355,60.033,36.408,87.641c-1.901,26.95-3.541,50.224-30.812,63.11c-3.755,1.774-5.978,5.729-5.542,9.859
    	c0.437,4.131,3.437,7.534,7.48,8.484c0.673,0.158,13.678,3.157,31.118,3.157c4.237,0,8.737-0.177,13.384-0.615
    	c28.156-2.652,50.074-13.772,64.104-32.348l0.818,4.088c3.833,19.166,14.615,35.788,30.442,47.099v14.035
    	c0,6.229-4.434,11.638-10.541,12.86l-29.325,5.865c-27.491,5.498-31.261,25.19-34.906,44.234c-0.864,4.515-1.758,9.183-2.97,14.03
    	c-1.309,5.235,1.874,10.54,7.108,11.848c0.796,0.199,1.593,0.294,2.378,0.294c4.377,0,8.36-2.963,9.47-7.402
    	c1.344-5.376,2.333-10.541,3.205-15.096c2.966-15.492,4.751-22.701,12.23-26.423v39.149c0,5.395,4.374,9.77,9.77,9.77
    	c5.395,0,9.77-4.374,9.77-9.77V239.95l9.95-1.99l0.017,3.222c0.092,17.401,11.245,32.789,27.753,38.292l18.883,6.294
    	c1.003,0.334,2.046,0.501,3.089,0.501c1.043,0,2.087-0.167,3.089-0.501l18.766-6.255c16.601-5.534,27.754-21.009,27.754-38.506
    	v-3.035l9.888,1.978v43.918c0,5.395,4.374,9.77,9.77,9.77c5.395,0,9.77-4.374,9.77-9.77v-39.149
    	c7.479,3.722,9.264,10.931,12.23,26.423c0.872,4.556,1.86,9.72,3.205,15.096c1.11,4.439,5.093,7.402,9.47,7.402
    	c0.784,0,1.582-0.094,2.378-0.294C294.832,292.038,298.015,286.733,296.706,281.498z M119.383,103.735V85.544h127.598v18.191
    	H119.383z M233.092,66.005h-99.818c11.349-15.238,29.489-25.133,49.907-25.133C203.6,40.872,221.742,50.767,233.092,66.005z
    	 M36.031,152.629c16.657-18.066,18.339-41.926,19.868-63.612c1.802-25.561,3.359-47.637,27.507-59.374
    	c14.068-6.838,26.402-8.467,36.654-4.842c7.466,2.638,12.649,7.688,15.796,11.685c-10.697,7.623-19.483,17.747-25.503,29.521h-0.74
    	c-5.395,0-9.77,4.374-9.77,9.77v37.73c0,3.463,1.807,6.498,4.525,8.233C88.891,148.343,57.842,153.014,36.031,152.629z
    	 M136.117,139.545l-3.254-16.27H233.5l-3.254,16.27c-2.977,14.881-11.57,27.713-24.197,36.131l-9.417,6.278
    	c-8.171,5.448-18.729,5.448-26.902,0l-9.417-6.278C147.687,167.257,139.094,154.425,136.117,139.545z M198.956,260.976
    	l-15.677,5.225l-15.794-5.265c-8.561-2.854-14.344-10.833-14.392-19.858l-0.052-9.866c8.475-5.978,13.898-15.836,13.898-26.703
    	v-2.066c5.216,2.092,10.728,3.144,16.242,3.144c5.514,0,11.027-1.053,16.242-3.144v2.066c0,10.878,5.434,20.746,13.926,26.722v9.776
    	C213.349,250.082,207.564,258.107,198.956,260.976z"/>
  </SvgIcon>
}
export const GuyAthlete = () => {
  return <SvgIcon
    viewBox={"0 0 297 297"}
  >
    <path d="M294.721,284.571l-7.071-28.283c-7.003-28.016-29.824-49.775-58.14-55.438l-27.604-5.521
    	c-5.374-1.074-9.275-5.833-9.275-11.314v-18.182c11.365-10.775,19.142-24.744,22.276-40.417l3.412-17.061h4.129
    	c5.523,0,10.001-4.478,10.001-10.001V73.097c1.431-3.837,2.226-7.982,2.226-12.313c0-18.521-14.301-33.764-32.439-35.27
    	C191.857,10.032,171.166,0,148.5,0s-43.357,10.032-53.736,25.513c-18.138,1.506-32.439,16.749-32.439,35.27
    	c0,4.331,0.795,8.476,2.226,12.313v25.256c0,5.523,4.478,10.001,10.001,10.001h4.129l3.412,17.061
    	c3.134,15.672,10.91,29.641,22.276,40.416v18.182c0,5.481-3.901,10.24-9.277,11.314l-0.069,0.014
    	c-0.02,0.004-0.041,0.008-0.061,0.012l-27.472,5.495c-28.316,5.663-51.138,27.423-58.141,55.437l-7.071,28.284
    	c-1.34,5.359,1.918,10.79,7.276,12.129c0.815,0.204,1.631,0.301,2.434,0.301c4.481,0,8.559-3.034,9.695-7.577l7.071-28.285
    	c3.515-14.062,12.465-25.973,24.419-33.427v59.287c0,5.523,4.478,10.001,10.001,10.001s10.001-4.478,10.001-10.001V220.11
    	l13.94-2.789l0.038,7.212c0.112,21.267,13.743,40.073,33.918,46.797l24.265,8.089c1.027,0.343,2.094,0.514,3.163,0.514
    	c1.068,0,2.136-0.171,3.163-0.513l24.117-8.039c20.287-6.764,33.917-25.674,33.917-47.058v-7.038l14.127,2.826v66.886
    	c0,5.523,4.478,10.001,10.001,10.001s10.001-4.478,10.001-10.001V227.71c11.954,7.454,20.904,19.366,24.419,33.429l7.071,28.284
    	c1.136,4.545,5.214,7.577,9.695,7.577c0.803,0,1.619-0.097,2.434-0.301C292.803,295.36,296.061,289.93,294.721,284.571z
    	 M84.554,88.351V76.086h127.893v12.265H84.554z M97.718,45.394c0.549,0,1.128,0.033,1.721,0.1c4.272,0.468,8.367-1.828,10.177-5.725
    	c5.408-11.637,21.397-19.765,38.884-19.765s33.476,8.128,38.884,19.765c1.81,3.896,5.906,6.19,10.177,5.725
    	c0.593-0.066,1.172-0.1,1.721-0.1c6.846,0,12.657,4.496,14.649,10.689H83.068C85.061,49.89,90.872,45.394,97.718,45.394z
    	 M101.707,121.492l-2.628-13.139h98.841l-2.628,13.139c-2.961,14.807-11.512,27.574-24.077,35.951l-9.409,6.272
    	c-8.084,5.389-18.531,5.388-26.615,0l-9.409-6.272C113.22,149.066,104.669,136.299,101.707,121.492z M169.453,252.405l-20.953,6.984
    	l-21.103-7.035c-12.039-4.012-20.173-15.236-20.24-27.926l-0.065-12.295c10.363-5.277,17.279-16.056,17.279-28.12v-3.479
    	c7.347,4.821,15.737,7.236,24.128,7.236s16.781-2.415,24.128-7.236v3.479c0,11.981,6.822,22.692,17.065,28.008v12.301
    	C189.693,237.084,181.559,248.369,169.453,252.405z"/>
  </SvgIcon>
}